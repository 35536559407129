export default {
	milhar: function (str) {
		// formata o numero com os separadores de milhar para ficar visualmente melhor de entender
		if (str || str === 0)
			return str.toString().replace(/\d(?=(?:\d{3})+(?:\D|$))/g, "$&.");
		else
			return "";
	},
	money: function (str) {
		// formata o numero com os separadores de milhar para ficar visualmente melhor de entender            
		if (str == 0) {
			return "R$ 0,00";
		}

		if (typeof str == "number") {
			str = str.toFixed(2);
		}

		if (str)
			return "R$ " + str.replace(".", ",").replace(/\d(?=(?:\d{3})+(?:\D|$))/g, "$&.");
		else
			return "";
	},
	date_time: function (str) {
		if (str) {
			if (str.substr(11, 8) == "00:00:00") {
				return str.substr(8, 2) + "/" + str.substr(5, 2) + "/" + str.substr(0, 4);
			} else {
				return str.substr(8, 2) + "/" + str.substr(5, 2) + "/" + str.substr(0, 4) + " " + str.substr(11, 2) + ":" + str.substr(14, 2) + ":" + str.substr(17, 2);
			}
		} else {
			return "";
		}

	},
	date: function (str) {
		if (str) {
			return str.substr(8, 2) + "/" + str.substr(5, 2) + "/" + str.substr(0, 4);
		} else {
			return "";
		}
	},
	cut: function (str) {
		if (typeof str !== "string") {
			return str;
		}
		// corta a string em um limit de palavras			
		let maxLength = 30; // limite de caracteres
		let strLength = str.length; // pegando o length da strng a ser tratadas

		if (maxLength >= strLength) {
			// se o limite for maior que a string já retorna ela direto
			return str;
		} else {
			// caso precise cortar, faz o tratamento para não cortar no meio da palavra
			for (let i = maxLength; i <= 100; i++) {
				if (str[maxLength] !== " ") {
					// se ainda está no meio de uma palavra aumenta o length e continua a percorrer
					maxLength++;
				} else {
					// se encontrou um intervalo entre duas palavras ele corta o loop e retorna a string
					return str.substr(0, maxLength) + " ... ";
				}
			}
			// caso dê algum problema e não encontre o momento de cortar, ele retorna a variável inteira por segurança
			return str;
		}
	},
	cnpfcnpj: function (str) {
		if (str == undefined) {
			return "";
		}
		if (str.length > 11) {
			// cnpj 
			return str.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g, "$1.$2.$3/$4-$5");
		} else {
			// cpf
			return str.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
		}
	},
	filterDelivery: function (time) {
		if (time > 1) {
			return time + " dias úteis";
		} else {
			return time + " dia útil";
		}
	},
	transacaoStatus: function (value) {
		let response = "";

		switch (value) {
		case "authorized":
		case "authorized_pending_capture":
			response = "Autorizado";
			break;
		case "refunded":
			response = "Estornado";
			break;
		case "waiting_payment":
			response = "Aguardando Pagamento";
			break;
		case "processing":
			response = "Processando";
			break;
		case "paid":
		case "approved":
			response = "Pago";
			break;
		case "not_authorized":
		case "refused":
		case "rejected":
			response = "Não Autorizada";
			break;
		case "failed":
			response = "Falhou";
			break;
		case "review":
			response = "Em Análise";
			break;
		case "problem":
			response = "Com Problemas";
			break;
		case "error":
			response = "Erro ao processar a transação";
			break;
		case "created":
			response = "Gerado";
			break;
		case "received":
			response = "Recebido";
			break;
		default:
			response = value;
			break;
		}

		return response;
	},
	orDefault: function (value, defaultValue) {
		if (value == "")
			value = defaultValue;
		return value;
	},
	cupomTipo: function (tipo) {
		let valor = "";
		switch (tipo) {
		case 1:
			valor = "Desconto em Reais";
			break;
		case 2:
			valor = "Desconto em Reais + Frete Grátis";
			break;
		case 3:
			valor = "Porcentagem";
			break;
		case 4:
			valor = "Porcentagem + Frete Grátis";
			break;
		case 5:
			valor = "Frete Grátis";
			break;
		}
		return valor;
	},
	orderBy: function (dados, field, direction) {
		dados.sort(function (a, b) {
			if (direction == "desc") {
				if (a[field] < b[field]) {
					return 1;
				}
				if (a[field] > b[field]) {
					return -1;
				}
			} else {
				if (a[field] > b[field]) {
					return 1;
				}
				if (a[field] < b[field]) {
					return -1;
				}
			}
			// a must be equal to b
			return 0;
		});

		return dados;
	},
	phone: function (str) {
		if (str) {
			str = str.replace(/\D+/g, "");
			let length = str.length - 2;
			switch (length) {
			case 10:
				return str.replace(/(\d{2})(\d{2})(\d{4})(\d{4})/g, "($1) $2 $3-$4");
			case 9:
				return str.replace(/(\d{2})(\d{1})(\d{4})(\d{4})/g, "($1) $2$3-$4");
			case 8:
				return str.replace(/(\d{2})(\d{4})(\d{4})/g, "($1) $2-$3");
			case 7:
				return str.replace(/(\d{2})(\d{7})/g, "($1) $2");
			case 6:
				return str.replace(/(\d{2})(\d{6})/g, "($1) $2");
			}
		}
		return "";
	},
	cep: function (str) {
		if (str) {
			str = str.replace(/\D+/g, "");
			return str.replace(/(\d{2})(\d{3})(\d{3})/g, "$1.$2-$3");
		}
		return "";
	},
	tipos_telefone: function (tipo) {
		if (tipo) {
			switch (tipo) {
			case "home":
				return "Residencial";
			case "cellphone":
				return "Celular";
			case "whatsapp":
				return "Whatsapp";
			case "work":
				return "Trabalho";
			}
		}
	},	
	numberToString: function (numero) {
		if (typeof numero == "number") {
			return numero.toString();
		}
		return numero;
	},
	store: (loja) => {
		if (typeof loja == "string") {
			loja = parseInt(loja);
		}
		switch (loja) {
		case 1:
			return "Mundo da Carabina";
		case 2:
			return "Aventura & Cia";
		case 3:
			return "Lexo";
		case 4:
			return "Marketplace";
		default:
			return "";
		}
	},
	storeSlug: (loja) => {		
		switch (loja) {
		case "mundo":
			return "Mundo da Carabina";
		case "aventura":
			return "Aventura & Cia";
		case "lexo":
			return "Lexo";
		case "marketplace":
			return "Marketplace";		
		default:
			return "Geral";
		}
	},
	company: (company) => {
		if (typeof company == "string") {
			company = parseInt(company);
		}		
		switch (company) {
		case 1:
			return "MEC";
		case 2:
			return "CEM";
		case 3:
			return "NOVO COMERCIO";
		case 5:
			return "LUXOR";
		case 6:
			return "COLOSSEO";
		case 7:
			return "LEXO";
		case 9:
			return "BUFALO";
		case 10:
			return "MRR";
		case 12:
			return "MDC Group";
		default:
			return "";
		}
	},
	sla: (sla) => {
		switch (sla) {
		case "urgent":
			return "Urgente";
		case "moderate":
			return "Moderado";
		case "normal":
			return "Normal";
		case "weekly":
			return "Semanal";
		default:
			return "";
		}
	},
	reviews: (review) => {
		if (typeof review == "string") {
			review = parseInt(review);
		}
		switch (review) {
		case 1:
			return "Ruim";
		case 2:
			return "Regular";
		case 3:
			return "Bom";
		case 4:
			return "Ótimo";
		case 5:
			return "Excelente";
		default:
			return "";
		}
	},
	StatusFraude: (status) => {
		if(!status){
			status = "";
		}
		status = status.toLowerCase();
		switch (status) {
		case "approved":
			return "Aprovado";
		case "review":
		case "pending":
			return "Em Análise";
		case "declined":
			return "Reprovado";
		case "not_analyzed":
			return "Não analisado";
		default:
			return status;
		}
	},
	marketplaces: (name) => {
		const marketplace = name.toUpperCase();
		switch (marketplace) {
		case "AMAZON":
			return "Amazon";
		case "B2W_NEW_API":
			return "B2W";
		case "CENTAURO_NEW_API":
			return "Centauro";
		case "CARREFOUR":
			return "Carrefour";
		case "CNOVA":
			return "Cnova";
		case "DECATHLON":
			return "Decathlon";
		case "LOJAS AMERICANAS":
			return "Americanas";
		case "MAGAZINE_LUIZA":
			return "Magazine Luiza";
		case "MADEIRA_MADEIRA":
			return "Madeira Madeira";
		case "MERCADO_LIVRE_AV":
			return "Mercado Livre Aventura";
		case "MERCADO_LIVRE":
			return "Mercado Livre Mundo";			
		case "NETSHOES":
			return "Netshoes";
		case "OLIST_NEW_API":
			return "Olist";
		case "SUBMARINO":
			return "Submarino";
		case "SHOPTIME":
			return "Shoptime";
		case "SHOPEE":
			return "Shopee";
		case "SHOPEE_AV":
			return "Shopee Aventura";
		case "VENTURESHOP":
			return "VentureShop";
		case "WALMART":
			return "Walmart";
		case "WEB_CONTINENTAL":
			return "Web Continental";
		default:
			return marketplace;
		}
	},
	payments: (name) => {
		const payment = name.toLowerCase();
		switch (payment) {
		case "mercadopago":
			return "Mercado Pago";
		case "mundipagg":
			return "MundiPagg";
		case "credit_card":
			return "Cartão de Crédito";
		case "deposit":
			return "Depósito";
		case "invoice":
			return "Boleto";
		case "marketplace":
			return "Marketplace";
		case "pix":
			return "PIX";
		case "voucher":
			return "Crédito Devolução";
		case "withdraw":
			return "Retirada";
		case "internal":
			return "Pagamento Interno";
		case "pagaleve":
			return "Paga Leve";
		default:
			return payment;
		}
	},
	carrier: (name) => {
		const carrier = name.toLowerCase();
		switch (carrier) {
		case "jadlog":
			return "Jadlog";
		case "loja":
			return "Retirada";
		case "marketplace":
			return "Marketplace Coleta";
		case "motoboy":
			return "Motoboy";
		case "transfolha":
			return "Transfolha";		
		default:
			return carrier;
		}
	}
};